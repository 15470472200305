import { RISK_SECTION_HIGH, RISK_SECTION_LOW, RISK_SECTION_MEDIUM, RISK_SECTION_VERY_HIGH } from '@consts/risk';
import {
  formatBMIQuestion,
  formatOption,
  formatQuestion,
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';
import { COL_SIZE_FULL, QUESTION_TYPE_GENDER } from '@consts/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 0, 2),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 2, 4.5),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 4.5, 6.5),
  formatRisk(RISK_SECTION_VERY_HIGH, 'common.very_high', 'risk.very_high', 6.5, 99999),
];

export const resultSettings = [
  formatResult(
    'With early treatment, you can speed up your recovery and reduce any risk of developing increased problems. At Joint Academy, you get assessment and treatment without the wait.',
    'Based on your answers, your knee pain causes you mild discomfort in your everyday life. However, it’s always good to get an assessment of your knee pain in good time if you suspect any underlying joint condition, or if someone in your family suffers from a joint condition. The development of many long-term joint conditions can be slowed down in an early stage, and much can be done proactively to reduce the risk of increased problems. The right treatment early on will also give you the best chance to maintain an active and pain-free life. Therefore, we recommend that you seek care for an assessment and treatment.<br/><br/>Today, exercise paired with education to help you better understand your condition is recommended as the first-line treatment for knee pain. This is the treatment that research shows provides the best results and pain relief, and is recommended by international guidelines.<br/><br/>With Joint Academy, you get a complete and clinically proven knee treatment straight to your phone. This means that you can start feeling better from the comfort of your home! When you start your treatment, you will connect with a personal physical therapist who will support you and be available on-demand throughout your treatment. Your physical therapist will help you get started with an exercise program tailored to reduce your knee pain and restore mobility. Your exercises only take 5 minutes a day.<br/><br/>2 out of 3 patients feel a positive change after just one month of treatment!',
    0,
    2,
  ),
  formatResult(
    'But don\'t worry! Treating your knee pain in time will reduce the risk of long-term problems. At Joint Academy, you get the right treatment without the wait.',
    'Based on your answers, your knee pain causes you moderate discomfort and appears to have a negative impact on your everyday life. With the right care, you can feel better and get back to doing what you love. Starting treatment early will avoid further stress on your joints and help you to reduce your pain faster. Therefore, we recommend that you seek help to get an assessment and treatment.<br/><br/>Today, exercise paired with education to help you better understand your condition is recommended as the first-line treatment for knee pain. This is the treatment that research shows provides the best results and pain relief, and is recommended by international guidelines.<br/><br/>With Joint Academy, you get a complete and clinically proven knee treatment straight to your phone. This means that you can start feeling better from the comfort of your home! When you start your treatment, you will connect with a personal physical therapist who will support you and be available on-demand throughout your treatment. Your physical therapist will help you get started with an exercise program tailored to reduce your knee pain and restore mobility. Your exercises only take 5 minutes a day.<br/><br/>2 out of 3 patients feel a positive change after just one month of treatment!',
    2,
    4.5,
  ),
  formatResult(
    'But don\'t worry! There are ways to treat knee pain that can get you back to doing what you love. At Joint Academy, you get the right treatment without the wait.',
    'Based on your answers, your knee pain causes you a high degree of discomfort and the risk of having long-term problems is high. With the right care, you can feel better and get back to everyday life with less pain and more mobility. Therefore, we recommend that you seek help to get an assessment and treatment.<br/><br/>Today, exercise paired with education to help you better understand your condition is recommended as the first-line treatment for knee pain. This is the treatment that research shows provides the best results and pain relief, and is recommended by international guidelines.<br/><br/>With Joint Academy, you get a complete and clinically proven knee treatment straight to your phone. This means that you can start feeling better from the comfort of your home! When you start your treatment, you will connect with a personal physical therapist who will support you and be available on-demand throughout your treatment. Your physical therapist will help you get started with an exercise program tailored to reduce your knee pain and restore mobility. Your exercises only take 5 minutes a day.<br/><br/>2 out of 3 patients feel a positive change after just one month of treatment!',
    4.5,
    99999,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Have you had knee pain during movement or strain in the last month?',
    2,
  ),
  formatBMIQuestion('question2'),
  formatQuestion(
    'question3',
    'Do you experience joint stiffness in the morning lasting up to 30-60 min?',
    [
      formatOption('common.yes', 1, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('No, no stiffness', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
      formatOption('No, long-lasting stiffness', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL),
    ],
  ),
  formatQuestion(
    'question4',
    'What is your gender?',
    [
      formatOption('Woman', 1, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'woman'),
      formatOption('Man', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'man'),
      formatOption('Other', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'man'),
    ],
    QUESTION_TYPE_GENDER,
  ),
  formatYesNoQuestion(
    'question5',
    'Do you have, or have had, a job with standing, walking, or rotating tasks?',
  ),
  formatYesNoQuestion(
    'question6',
    'Have you ever seeked medical attention due to a serious knee injury?',
    2,
  ),
];

export const references = [
  'American College of Rheumatology. Criteria [Internet]. Atlanta: American College of Rheumatology [cited 2021 June 16] From: <a href="https://www.rheumatology.org/Practice-Quality/Clinical-Support/Criteria">https://www.rheumatology.org/Practice-Quality/Clinical-Support/Criteria</a>',
  'Altman R, Alarcón G, Appelrouth D, et al. The American College of Rheumatology criteria for the classification and reporting of osteoarthritis of the hip. Arthritis Rheum. 1991;34(5):505-514. doi:10.1002/art.1780340502',
  'Saberi Hosnijeh F, Kavousi M, Boer CG, et al. Development of a prediction model for future risk of radiographic hip osteoarthritis. Osteoarthritis Cartilage. 2018;26(4):540-546. doi:10.1016/j.joca.2018.01.015',
  'Altman R, Asch E, Bloch D, et al. Development of criteria for the classification and reporting of osteoarthritis. Classification of osteoarthritis of the knee. Diagnostic and Therapeutic Criteria Committee of the American Rheumatism Association. Arthritis Rheum. 1986;29(8):1039-1049. doi:10.1002/art.1780290816',
  'Zheng H, Chen C. Body mass index and risk of knee osteoarthritis: systematic review and meta-analysis of prospective studies. BMJ Open. 2015 Dec 11;5(12):e007568. doi: 10.1136/bmjopen-2014-007568. PMID: 26656979; PMCID: PMC4679914.',
  'Felson et al, Arthr. and Rheum, 1997Felson DT, Zhang Y, Hannan MT, et al. Risk factors for incident radiographic knee osteoarthritis in the elderly: the Framingham Study. Arthritis Rheum. 1997;40(4):728-733. doi:10.1002/art.1780400420',
  'Blagojevic M, Jinks C, Jeffery A, Jordan KP. Risk factors for onset of osteoarthritis of the knee in older adults: a systematic review and meta-analysis. Osteoarthritis Cartilage. 2010;18(1):24-33. doi:10.1016/j.joca.2009.08.010',
];
